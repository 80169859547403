import { defineMessages } from 'react-intl';

export default defineMessages({
    link1: {
        id: 'link1',
        defaultMessage: 'What We Do',
    }, 
    link2: {
        id: 'link2',
        defaultMessage: 'Contact',
    },
});
