import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html, body, #root {
        width: 100%;
        height: 100%;
    }

    body {
        background-color: black;
        background-image: linear-gradient(to right, black , yellow);
    }   
`;
  