import React from 'react';

import { Container, SVG, P, A } from './StyledComponents';
import MailLogo from '../../../../assets/mail.svg';
import PhoneLogo from '../../../../assets/phone.svg';
import CardLogo from '../../../../assets/credit-card.svg';
import ClockLogo from '../../../../assets/clock.svg';
import Constants from '../../../../constants';

export default () => (
    <Container>
        <P><SVG src={MailLogo} alt="Mail address" /><A href={`mailto:${Constants.EMAIL}`}>{Constants.EMAIL}</A></P>
        <P><SVG src={PhoneLogo} alt="Phone number" /><A href={`tel:${Constants.PHONE}`}>{Constants.PHONE}</A></P>
        <P><SVG src={ClockLogo} alt="Opening times" />Monday to Friday 8.00am 5.30pm. Saturdays 8.00am to 12.00pm</P>
        <P><SVG src={CardLogo} alt="Card payments accepted" />{"Card payments accepted"}</P>
    </Container>
);