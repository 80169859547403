import React, { Suspense, lazy } from 'react';

import { Card, CardTitle, CardText, CardBody, CardImgContainer } from './StyledComponents';

const CardImg = lazy(() => import('./CardImg'));

export default (props) => {
    const {
        multiline,
        title,
        imgSrc,
        text: defaultText,
    } = props;

    let text = null;

    if (multiline) {
        text = [];
        let i = 0;

        for (const key in defaultText) {
            if (
                key !== 'title'
                && (key[0] === 'p' && !isNaN(key[1]) && (key.length === 2 || key.length === 3))
            ) {
                text.push(<CardText key={i} nomargin="true">{defaultText[key]}</CardText>);
                i++;
            }
        }
    } else {
        text = <CardText>{defaultText}</CardText>;
    }

    return (
        <Card>
            <CardImgContainer>
                <Suspense fallback={<div>Loading...</div>} >
                    <CardImg src={imgSrc} alt={title} />
                </Suspense>
            </CardImgContainer>
            <CardBody>
                <CardTitle>{title}</CardTitle>
                {text}
            </CardBody>
        </Card>
    );
};