import styled, { css } from 'styled-components';
import {
    Card as CardDefault,
    CardBody as CardBodyDefault,
    CardTitle as CardTitleDefault,
    CardText as CardTextDefault,
} from 'reactstrap';

const CardBody = styled(CardBodyDefault)`
    padding: 20px;
`;

const Card = styled(CardDefault)`
    width: 402px;
    height: 900px;
    font-size: medium;
    margin: 20px;
    background-color: black;
    border: 1px solid yellow;
    border-radius: 4px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
    @media (max-width: 750px) {
        width: 90%;
    }
`;

const CardTitle = styled(CardTitleDefault)`
    font-size: x-large;
    color: yellow;
    text-align: center;
`;

const CardText = styled(CardTextDefault)`
    color: yellow;

    ${props => props.nomargin && css`
        margin: 0;
    `}
`;

const CardImgContainer = styled.div`
    height 400px;
    width: 400px;
    overflow: hidden;

    @media (max-width: 750px) {
        width: 100%;
    }
`;

export {
    Card,
    CardBody,
    CardTitle,
    CardText,
    CardImgContainer,
};