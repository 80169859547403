import { defineMessages } from 'react-intl';

const scope = 'whatwedo';

export default defineMessages({
    mainTitle: {
        id: `${scope}.maintitle`,
        defaultMessage: 'What We Do',
    },
    boilerInstalation: {
        title: {
            id: `${scope}.boilerinstalation.title`,
            defaultMessage: 'Boiler Installation',
        },
        text: {
            id: `${scope}.boilerinstalation.text`,
            defaultMessage: `Buying a new boiler tends to be a decision that most people need to make in hurry. Choosing an
                unreliable boiler or making the wrong decision about part of the installation it is a mistake you
                and your bank balance will late regret. We have consulted industry experts so you can know how to get
                the best boiler installation`,
        },
    },
    gasMaintenance: {
        title: {
            id: `${scope}.gasmaintenance.title`,
            defaultMessage: 'Gas Maintenance',
        },
        text: {
            id: `${scope}.gasmaintenance.text`,
            defaultMessage: `All your repairs and installations are carried out by highly skilled gas safe registered engineer.
                We are experienced in all fields of boiler repair and installation, and will always aim to diagnose your problem
                quickly and efficently saving your inconvenient time and extra costs. Once the problem has been diagnosed, we will
                use the most appropiate solution to ensure your heating is working again as quickly as possible.`,
        },
    },
    gasInstallation: {
        title: {
            id: `${scope}.gasinstallation.title`,
            defaultMessage: 'Gas Installation',
        },
        p1: {
            id: `${scope}.gasinstallation.p1`,
            defaultMessage: `Whether it is a full system installation, repair works or maintenance you require, get in touch for
                friendly advice quick, efficient service.`,
        },
        p2: {
            id: `${scope}.gasinstallation.p2`,
            defaultMessage: '- Domestic / Commercial Boilers (NG or CPG)',
        },
        p3: {
            id: `${scope}.gasinstallation.p3`,
            defaultMessage: '- Fires',
        },
        p4: {
            id: `${scope}.gasinstallation.p4`,
            defaultMessage: '- Cookers / Range',
        },
        p5: {
            id: `${scope}.gasinstallation.p5`,
            defaultMessage: '- Hob',
        },
        p6: {
            id: `${scope}.gasinstallation.p6`,
            defaultMessage: '- Water Heaters',
        },
        p7: {
            id: `${scope}.gasinstallation.p7`,
            defaultMessage: '- Vented / Unvented Cylinder',
        },
        p8: {
            id: `${scope}.gasinstallation.p7`,
            defaultMessage: '- Warm Air Unit',
        },
    },
    plumbingService: {
        title: {
            id: `${scope}.plumbingservice.title`,
            defaultMessage: 'Plumbing Services',
        },
        p1: {
            id: `${scope}.plumbingservice.p1`,
            defaultMessage: '- Air Locks',
        },
        p2: {
            id: `${scope}.plumbingservice.p2`,
            defaultMessage: '- Ball Valves',
        },
        p3: {
            id: `${scope}.plumbingservice.p3`,
            defaultMessage: '- Blocked Toilets and Sinks',
        },
        p4: {
            id: `${scope}.plumbingservice.p4`,
            defaultMessage: '- Burst Pipes',
        },
        p5: {
            id: `${scope}.plumbingservice.p5`,
            defaultMessage: `- Toilet Repairs and Installs`,
        },
        p6: {
            id: `${scope}.plumbingservice.p6`,
            defaultMessage: `- Floods`,
        },
        p7: {
            id: `${scope}.plumbingservice.p7`,
            defaultMessage: `- Hot Water / Unvented Cylinder Installations`,
        },
        p8: {
            id: `${scope}.plumbingservice.p9`,
            defaultMessage: `- Water Heater Repairs and installs`,
        },
        p9: {
            id: `${scope}.plumbingservice.p9`,
            defaultMessage: `- Immersions Heaters`,
        },
        p10: {
            id: `${scope}.plumbingservice.p10`,
            defaultMessage: `- Tap Repairs and Installations`,
        },
        p11: {
            id: `${scope}.plumbingservice.p11`,
            defaultMessage: `- OverFlows`,
        },
        p12: {
            id: `${scope}.plumbingservice.p12`,
            defaultMessage: `- Pipeworks Repair`,
        },
        p13: {
            id: `${scope}.plumbingservice.p13`,
            defaultMessage: `- Stop Taps`,
        },
        p14: {
            id: `${scope}.plumbingservice.p14`,
            defaultMessage: `- Showers`,
        },
        p15: {
            id: `${scope}.plumbingservice.p15`,
            defaultMessage: `- Full Bathroom`,
        },
        p16: {
            id: `${scope}.plumbingservice.p16`,
            defaultMessage: `- Saniflo Installation`,
        },
    },
    centralHeating: {
        title: {
            id: `${scope}.centralheating.title`,
            defaultMessage: 'Central Heating',
        },
        p1: {
            id: `${scope}.centralheating.p1`,
            defaultMessage: 'Our engineers deal everyday with all short of issues.',
        },
        p2: {
            id: `${scope}.centralheating.p2`,
            defaultMessage: '- Replacement Radiators and Valves',
        },
        p3: {
            id: `${scope}.centralheating.p3`,
            defaultMessage: '- Leaking Pipes',
        },
        p4: {
            id: `${scope}.centralheating.p4`,
            defaultMessage: '- Underfloor heating',
        },
        p5: {
            id: `${scope}.centralheating.p5`,
            defaultMessage: `- Faulty Pumps`,
        },
        p6: {
            id: `${scope}.centralheating.p6`,
            defaultMessage: `- Chemical Flush`,
        },
        p7: {
            id: `${scope}.centralheating.p7`,
            defaultMessage: `- System Balancing`,
        },
        p8: {
            id: `${scope}.centralheating.p9`,
            defaultMessage: `- Zone Valve Repair and Replacement`,
        },
        p9: {
            id: `${scope}.centralheating.p9`,
            defaultMessage: `- Heating System Noise`,
        },
        p10: {
            id: `${scope}.centralheating.p10`,
            defaultMessage: `- Expansion Vessel`,
        },
        p11: {
            id: `${scope}.centralheating.p11`,
            defaultMessage: `- Corrosion Prevention`,
        },
    },
    commercialBoilerRoom: {
        title: {
            id: `${scope}.commercialboilerroom.title`,
            defaultMessage: 'Commercial Boiler Room',
        },
        p1: {
            id: `${scope}.commercialboilerroom.p1`,
            defaultMessage: `Most of organisations do not know how to correctly maintain their plant room
                equipment. As result their heating and energy sytems perform sub-optimally, leading to downtime
                and excessive cost. It Gas LTD have the capability to manage , maintain and optmise all equipment
                within yor plant room:`,
        },
        p2: {
            id: `${scope}.commercialboilerroom.p2`,
            defaultMessage: '- Boiler installation, maintenance and repair',
        },
        p3: {
            id: `${scope}.commercialboilerroom.p3`,
            defaultMessage: '- Installation, repair, maintenance all related equipment',
        },
        p4: {
            id: `${scope}.commercialboilerroom.p4`,
            defaultMessage: '- Unvented cylinder',
        },
        p5: {
            id: `${scope}.commercialboilerroom.p5`,
            defaultMessage: `- High level gas heater`,
        },
    },
});