import styled from 'styled-components';

const Container = styled.div`
    background-color: black;
    color: yellow;
    margin: 20px;
    width: 40%;
    border: 1px solid yellow;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
    padding: 35px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SVG = styled.img`
    width: 35px;
    padding-right: 10px;
`;

const P = styled.p`
    width: 100%;
    display: flex;
    align-items: center;
`;

const A = styled.a`
    &:hover, &:visited, & {
        color: yellow;
        text-decoration: none;
    }

    &:hover {
        color: #b5b50d;
    }
`;

export {
    Container,
    SVG,
    P,
    A,
};