import React from 'react';

import SecondaryTitle from '../../secondary-title';
import Container from '../../container';
// import Form from './form';
import { FormContainer } from './StyledComponents';
import ContactInfo from './contact-info';
import Map from './map';

export default () => (
    <Container>
        <SecondaryTitle id={"contact"} text="Contact Us" />
        <FormContainer>
            <ContactInfo />
            {/* <Form /> */}
        </FormContainer >
        <Map />
    </Container >
);