import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    margin-bottom: 100px;
`;

const P = styled.p`
    color: yellow;
`;

const TextCard = styled.div`
    background-color: black;
    border: 1px solid yellow;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
`;

export {
    Container,
    P,
    TextCard,
};