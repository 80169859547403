import styled from 'styled-components';

export default styled.div`
    height: 500px;
    width: 60%;
    padding: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;