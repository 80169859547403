import styled from 'styled-components';

const FormContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 50px;
`;

export {
    FormContainer,
};