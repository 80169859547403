import styled from 'styled-components';

 const H2 = styled.h2`
    color: yellow;
    text-align: center;
    margin: 0px;
    font-weight: bold;
    text-shadow:
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    margin-bottom: 50px;
`;


export {
    H2,
};
