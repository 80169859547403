import { defineMessages } from 'react-intl';

export default defineMessages({
    mainTitle: {
        id: 'maintitle',
        defaultMessage: 'IT GAS LTD',
    },
    mainSubTitle: {
        id: 'mainsubtitle',
        defaultMessage: 'Your gas engineers and plumbing specialists in Wigan, Liverpool and Manchester Areas',
    },
    buttonText: {
        id: 'buttontext',
        defaultMessage: 'Contact Us',
    },
});
