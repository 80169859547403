import React from 'react';

import Title from './title';
import WhatWeDo from './what-we-do';
import Contact from './contact';
import MainText from './main-text';
import { BodyContainer } from './StyledComponents';

export default () => (
    <BodyContainer>
        <Title />
        <MainText />
        <WhatWeDo />
        <Contact />
    </BodyContainer>
);

