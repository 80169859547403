import React, { Fragment } from 'react';
import { Container } from 'reactstrap';

import { Header, Body, Footer } from './components';
import LanguageProvider from './lang';
import GlobalStyle from './GlobalStyle';

const App = () => {
    document.addEventListener('touchstart', window.ontouchstart, {passive: true});
    return (
        <Fragment>
            <LanguageProvider>
                <Container className="p-0 w-100 h-100" fluid>
                    <Header />
                    <Body />
                    <Footer />
                </Container>
            </LanguageProvider>
            <GlobalStyle />
        </Fragment>
    );
}

export default App;
