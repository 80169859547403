import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Collapse,
    Nav,
    NavItem,
    NavbarToggler,
} from 'reactstrap';

import { Navbar, NavbarBrand, NavLink } from './StyledComponets';
import { applyFunction } from '../../utils';
import costants from '../../constants';
import componentMessages from './messages';

export default () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const { formatMessage } = useIntl();

    const messages = {
        ...applyFunction({ ...componentMessages }, formatMessage),
    }; 

    const companyName = String(costants.COMPANY_NAME).toLocaleUpperCase();

    return (
        <Navbar className="navbar-light" expand="md">
            <NavbarBrand href="/"><p>{companyName}</p></NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-md-auto" navbar>
                    <NavItem>
                        <NavLink href="#what-we-do" className="py-0">{messages.link1}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#contact" className="py-0">{messages.link2}</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}