import React from 'react';
import { useIntl } from 'react-intl';

import { H1, H3, Container, Button, Span } from './StyledComponents';
import componentMessages from './messages';

export default () => {
    const { formatMessage } = useIntl();

    const messages = {
        mainTitle: formatMessage(componentMessages.mainTitle),
        mainSubTitle: formatMessage(componentMessages.mainSubTitle),
        buttonText: formatMessage(componentMessages.buttonText),
    };

    return (
        <Container>
            <H1>{messages.mainTitle}</H1>
            <H3>{messages.mainSubTitle}</H3>
            <Button ariaLabel="Contact Us Button" href="#contact" onClick={() => document.activeElement.blur()}><Span>{messages.buttonText}</Span></Button>
        </Container>
    )
};
