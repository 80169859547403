import React from 'react';
import { useIntl } from 'react-intl';

import { CardContainer } from './StyledComponents';
import Container from '../../container';
import SecondaryTitle from '../../secondary-title';
import Card from './card';
import { applyFunction } from '../../../utils';
import componentMessages from './messages';

const file = navigator.userAgent.indexOf("Chrome") > -1 ? 'webp' : 'jpeg';

const BoilerInstallation = require(`../../../assets/boiler-installation.${file}`);
const GasMaintenance = require(`../../../assets/gas-maintenance.${file}`);
const GasInstallation = require(`../../../assets/gas-installation.${file}`);
const PlumbingService = require(`../../../assets/plumbing-service.${file}`);
const CentralHeating = require(`../../../assets/central-heating.${file}`);
const ComercialBoilerRoom = require(`../../../assets/comercial-boiler-room.${file}`);

export default () => {
    const { formatMessage } = useIntl();

    const messages = {
        mainTitle: formatMessage(componentMessages.mainTitle),
        boilerInstalation: { ...applyFunction({ ...componentMessages.boilerInstalation }, formatMessage) },
        gasMaintenance: { ...applyFunction({ ...componentMessages.gasMaintenance }, formatMessage) },
        gasInstallation: { ...applyFunction({ ...componentMessages.gasInstallation }, formatMessage) },
        plumbingService: { ...applyFunction({ ...componentMessages.plumbingService }, formatMessage) },
        centralHeating: { ...applyFunction({ ...componentMessages.centralHeating }, formatMessage) },
        commercialBoilerRoom: { ...applyFunction({ ...componentMessages.commercialBoilerRoom }, formatMessage) },
    };

    return (
        <Container>
            <SecondaryTitle id={"what-we-do"} text={messages.mainTitle}></SecondaryTitle>
            <CardContainer>
                <Card title={messages.boilerInstalation.title} imgSrc={BoilerInstallation} text={messages.boilerInstalation.text} />
                <Card title={messages.gasMaintenance.title} imgSrc={GasMaintenance} text={messages.gasMaintenance.text} />
                <Card title={messages.gasInstallation.title} imgSrc={GasInstallation} text={messages.gasInstallation} multiline />
                <Card title={messages.plumbingService.title} imgSrc={PlumbingService} text={messages.plumbingService} multiline />
                <Card title={messages.centralHeating.title} imgSrc={CentralHeating} text={messages.centralHeating} multiline />
                <Card title={messages.commercialBoilerRoom.title} imgSrc={ComercialBoilerRoom} text={messages.commercialBoilerRoom} multiline />
            </CardContainer>
        </Container>
    );
}