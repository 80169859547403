import React, { Component } from 'react';

import Container from './StyledComponents';

class SimpleMap extends Component {
    render() {
        return (
            <Container>
                <iframe rel="preconnect" height="100%" width="100%" title="It GAS LTD address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.220060262808!2d-2.6960391308307736!3d53.57168906550946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b0fd5de023d19%3A0xcbd7a3193aa16180!2s11%20Gathurst%20Ln%2C%20Shevington%2C%20Wigan%20WN6%208HA%2C%20UK!5e0!3m2!1sen!2ses!4v1582905383221!5m2!1sen!2ses" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
            </Container>
        );
    }
}

export default SimpleMap;