import React from 'react';
import { Row } from 'reactstrap';

import { Footer, PaddedRow, SafeLogo, P, SVG, Col, A } from './StyledComponents';
import logo from '../../assets/gas-safe-5.png';
import constants from '../../constants/index'; 
import Mail from '../../assets/mail.svg';
import Phone from '../../assets/phone.svg';

export default () => (
    <Footer className="w-100">
        <PaddedRow>
            <Col className="d-flex flex-column justify-content-center">
                <Row>
                    <Col>
                        <SVG src={Phone} alt="Phone Symbol" className="lazyload" />
                        <P><A href={`tel:${constants.PHONE}`}>{constants.PHONE}</A></P>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SVG src={Mail} alt="Mail Symbol" className="lazyload" />
                        <A href={`mailto:${constants.EMAIL}`}>{constants.EMAIL}</A>
                    </Col>
                </Row>
            </Col>
            <Col className="text-center">
                <SafeLogo src={logo} alt="Safe Logo" className="lazyload" />
            </Col>
        </PaddedRow>
    </Footer>
);