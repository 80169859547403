import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

import { H2 } from './StyledComponents';

export default (props) => {
    let component = null;

    if (props.id) {
        component = <ScrollableAnchor id={props.id}><H2>{props.text}</H2></ScrollableAnchor>;
    } else {
        component= <H2>{props.text}</H2>;
    }

    return component;
}
