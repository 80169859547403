import styled from 'styled-components';
import {
    Navbar as NavbarDefault,
    NavbarBrand as NavbarBrandDefault,
    NavbarToggler as NavbarTogglerDefault,
    NavLink as NavLinkDefault,
} from 'reactstrap';

const Navbar = styled(NavbarDefault)`
    background: linear-gradient(40deg, black 50%, yellow 50%);
    border-bottom: 0.5px solid yellow;
    position: fixed;
    width: 100%;
    z-index: 99999;
`;

const NavbarToggler = styled(NavbarTogglerDefault)`
    border-color: black !important;
`;

const NavLink = styled(NavLinkDefault)`
    color: yellow !important;
    font-size: large;
    font-weight: bold;

    @media (min-width: 768px) {
        color: black !important;
    }
`;

const NavbarBrand = styled(NavbarBrandDefault)`
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 60px;

    & p {
        color: yellow;
        margin: 0px;
        font-size: xx-large;
        font-weight: bold;
        text-shadow:
            -1px -1px 0 #000,  
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
`;

export {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavLink,
};