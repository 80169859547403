import React from 'react';

import { Container, P, TextCard } from './StyledComponents';

export default () => {
    return (
        <Container>
            <TextCard>
                <P>We are boiler and heating engineers gas safe registered and Check a Trade approved, and We are ready for all emergencies, repairs or installations; domestic or commercial.</P>
                <P>We are covering: Wigan, Shevington, Ormskirk, Appley bridge, Skermsdale, St. Helen's, Standish, Wrightington, Up Holland, Orrell, Eccleston, Chorley, Blackrod, Parlbold, Leigh, Newton-le-willows, Rufford,  Horwich, Southport, Formby, Birkdale, Leyland, Rivington, Scarisbrick…</P>
            </TextCard>
        </Container>
    );
}