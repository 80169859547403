import styled from 'styled-components';
import { Col as DefaultCol, Row } from 'reactstrap';

const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    background-color: black;
    border-top: 0.5px solid yellow;
    color: yellow;
    font-size: small;
`;

const PaddedRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const SafeLogo = styled.img`
    width: 60px;
`;

const P = styled.p`
    margin-bottom: 0px !important;
    text-align: center;
`;

const Col = styled(DefaultCol)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SVG = styled.img`
    width: 17px;
    margin-right: 6px;
`;

const A = styled.a`
    &:hover, &:visited, & {
        color: yellow;
        text-decoration: none;
    }

    &:hover {
        color: #b5b50d;
    }
`;

export {
    Footer,
    PaddedRow,
    SafeLogo,
    P,
    SVG,
    Col,
    A,
};

