import styled from 'styled-components';

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export {
    CardContainer,
};
