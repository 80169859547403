import styled from 'styled-components';

import { Button as defaultButton } from 'reactstrap';

const H1 = styled.h1`
    color: yellow;
    text-align: center;
    margin: 0px;
    font-weight: bold;
    text-shadow:
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
`;
const H3 = styled.h3`
    color: yellow;
    text-align: center;
    margin: 0px;
    font-weight: bold;
    text-shadow:
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
`;

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Button = styled(defaultButton)`
    margin-top: 20px;
    background-color: black;
    border: 1px solid yellow;

    &:hover {
        background-color: #242624;
    }

    &:focus {
        outline: none;
    }
`;

const Span = styled.span`
    color: yellow;
`;

export {
    H1,
    H3,
    Container,
    Button,
    Span,
};
