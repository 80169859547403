import React from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from './translations/en.json';

const messages = {
    en: enMessages,
};

const defaultLocale = 'en';

const LanguageProvider = props => (
    <IntlProvider
        locale={defaultLocale} messages={messages[defaultLocale]}>
        {React.Children.only(props.children)}
    </IntlProvider>
);

export default LanguageProvider;
